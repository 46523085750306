/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as Sentry from '@sentry/react';
import App from './App';
import * as serviceWorker from './serviceWorker';

if (window._env_.SENTRY_ENABLED === 'true') {
  Sentry.init({
    dsn: window._env_.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    ignoreErrors: [
      /mirai/i,
      /style/i,
      /fbq/i,
      /ttq/i,
      /gtm/i,
      /Cookiebot/i,
      /ReferenceError.*fbq/i,
      /document\.getElementById.*CookiebotWidget/i,
      /gtm\.js/i,
    ],
    beforeSend(event) {
      // Comprobar si el stack trace existe
      if (event.exception && event.exception.values) {
        const exception = event.exception.values[0];
        
        if (exception.stacktrace && exception.stacktrace.frames) {
          const {frames} = exception.stacktrace;
          
          // Verificar si algún frame en el stack trace contiene "gtm.js"
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < frames.length; i++) {
            if (frames[i].filename && frames[i].filename.includes('gtm.js')) {
              // Ignorar el evento
              return null;
            }
          }
        }
      }
      
      // Permitir que el evento se envíe si no cumple las condiciones anteriores
      return event;
    },
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
