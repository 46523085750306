import React, { useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Typography } from 'antd';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import {
  paymentDetails,
  priceDetails,
  nightsResume,
  totalResume,
  ivaFee,
  reservationDetails,
  payNowWrapper,
  payNowAmount,
  payNowDetails,
  payNowDetailsCamping,
  reservationDescription,
  reservationResume,
  reservationRoomName,
  reservationGuests,
  reservationDates,
  planResume,
  nightsRow,
} from './index.module.scss';
import TribeTag from '../../../../../components/TribeTag';
import { ReactComponent as Icon } from '../../../../../assets/icons/tent.svg';
import { useReservationState } from '../../../../../services/Reservation';

function PaymentDetails({
  totalPrice,
  reservationFee,
  daysBetweenCheckInAndCheckOut,
  agencyMode,
}) {
  const reservationFeeDecimal = reservationFee / 100;
  const nightPrice = totalPrice / daysBetweenCheckInAndCheckOut;
  const feePrice = totalPrice * reservationFeeDecimal;
  const amountLeft = totalPrice * (1 - reservationFeeDecimal);
  const ivaPercentage = 0.1;
  const intl = useIntl();
  const reservation = useReservationState();

  const formatCancellationPolicy = () => {
    const deadlineCalc = moment(reservation?.startDate).subtract(
      reservation?.deadlineDay,
      'days',
    );
    const today = moment();

    if (deadlineCalc.isBefore(today) || reservation?.nonRefundable === true) {
      return intl.formatMessage({ id: 'RatesSelector.NoRefundText' });
    }
    return (
      <FormattedMessage
        id="RatesSelector.FreeRefundDate"
        values={{
          freeRefundDate: moment(reservation?.startDate)
            .subtract(reservation?.deadlineDay, 'days')
            .format('dddd, D [de] MMMM'),
          yearDate: moment(reservation?.startDate)
            .subtract(reservation?.deadlineDay, 'days')
            .format('YYYY'),
        }}
        tagName="span"
      />
    );
  };
  const tribePromotion = useMemo(
    () => reservation?.promotionInfo?.tagList?.length > 0,
    [reservation],
  );
  const tagName = useMemo(() => {
    const tagList = reservation?.promotionInfo?.tagList;
    if (tagList && tagList.length > 0) {
      return tagList[0]?.name;
    }
    return undefined;
  }, [reservation]);

  const formatTribeTagList = useMemo(() => {
    if (tagName === undefined) return null;

    return <TribeTag key={tagName} tagName={tagName} />;
  }, [tagName]);

  return (
    <div className={paymentDetails}>
      <Typography.Title level={2}>
        {intl.formatMessage({ id: 'Payment.Details.Reservation' })}
      </Typography.Title>
      <div className={reservationDetails}>
        <p className={reservationDates}>
          {moment(reservation.startDate)
            .locale(intl.locale)
            .format('ddd, DD MMM')}
          {' - '}
          {moment(reservation.endDate)
            .locale(intl.locale)
            .format('ddd, DD MMM')}
        </p>
        <span className={reservationResume}>
          <Icon />
          <span className={reservationDescription}>
            <p className={reservationRoomName}>
              {reservation.selectedRoomType}
              {' - '}
              {reservation.selectedHotel}
            </p>
            <p className={reservationGuests}>
              {`x${reservation.adults} ${intl.formatMessage({
                id: 'typeOfPerson.adults',
              })}, 
              x${reservation.children} ${intl.formatMessage({
                id: 'typeOfPerson.child',
              })}, 
              x${reservation.babies} ${intl.formatMessage({
                id: 'typeOfPerson.babies',
              })}`}
            </p>
          </span>
        </span>
      </div>
      <Typography.Title level={2}>
        {intl.formatMessage({ id: 'Payment.Details.Title' })}
      </Typography.Title>

      <div className={priceDetails}>
        <div className={nightsResume}>
          <div className={nightsRow}>
            <p>
              {intl.formatMessage(
                { id: 'Payment.Details.NightsResume' },
                { nightPrice, nightsNumber: daysBetweenCheckInAndCheckOut },
              )}
            </p>
            <p>
              {intl.formatNumber(totalPrice, {
                style: 'currency',
                currency: 'EUR',
              })}
            </p>
          </div>
          <div className={planResume}>
            {tribePromotion ? (
              <>
                <p>{reservation?.rateName}</p>
                &nbsp;
                {formatTribeTagList}
              </>
            ) : (
              <>
                <p>{reservation?.rateName}</p>
                &nbsp;
                <span>{reservation?.offerInfo?.nameI18n}</span>
                &nbsp;
                <span>{reservation?.promotionInfo?.nameI18n}</span>
              </>
            )}
          </div>
          <span>{formatCancellationPolicy()}</span>
        </div>
        <span className={totalResume}>
          <p>{intl.formatMessage({ id: 'Payment.Details.Total' })}</p>
          <p>
            {intl.formatNumber(totalPrice, {
              style: 'currency',
              currency: 'EUR',
            })}
          </p>
        </span>
        <p className={ivaFee}>
          {agencyMode
            ? intl.formatMessage({ id: 'Payment.Details.AgencyMode.IVA' })
            : intl.formatMessage(
                { id: 'Payment.Details.IVA' },
                { ivaPercentage },
              )}
        </p>
      </div>

      <div className={payNowWrapper}>
        <span className={payNowAmount}>
          <p>
            {intl.formatMessage(
              { id: 'Payment.Details.PayNow.Fee' },
              { reservationFee },
            )}
          </p>
          <p>
            {intl.formatNumber(feePrice, {
              style: 'currency',
              currency: 'EUR',
            })}
          </p>
        </span>
        {amountLeft > 0 && (
          <div className={payNowDetails}>
            {agencyMode && (
              <p>
                {intl.formatMessage(
                  { id: 'Payment.Details.PayNow.AgencyMode.AmountLeft' },
                  { amountLeft },
                )}
              </p>
            )}
            <span className={payNowDetailsCamping}>
              <p>
                {intl.formatMessage(
                  { id: 'Payment.Details.PayNow.Details.Camping.AmountLeft' },
                  { amountLeft },
                )}
              </p>
              <p>
                {intl.formatMessage({
                  id: 'Payment.Details.PayNow.Details.Camping.AmountLeftInfo',
                })}
              </p>
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

PaymentDetails.propTypes = {
  totalPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  reservationFee: PropTypes.number.isRequired,
  daysBetweenCheckInAndCheckOut: PropTypes.number.isRequired,
  agencyMode: PropTypes.bool.isRequired,
};

export default PaymentDetails;
