/* eslint-disable no-underscore-dangle */
import React, { useContext, useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { navigate } from '@reach/router';
import moment from 'moment';
// import CheckoutForm from './CheckoutForm';
import { useReservationState } from '../../../../services/Reservation';
import { paymentWrapper } from './index.module.scss';
import useCampings from '../../../../services/Campings/useCampings';
import CancellationPolicy from '../../../../components/CancellationPolicy';
import PaymentDetails from './PaymentDetails';
import PaymentResume from './PaymentResume';
import calcDaysBetweenCheckInAndCheckOut from '../../../../utils/daysBetweenCheckInAndCheckOut';
import PaymentLoader from '../../../../components/PaymentLoader';
import { CheckoutContext } from '../..';
import ROUTES from '../../../../routes/routes';
import STEP from '../steps';
import CheckoutPage from './CheckoutPage';
import {LanguageContext} from '../../../../locale/contexts/Language';

const Payment = injectIntl(() => {
  const { currentLanguage } = useContext(LanguageContext);
  const stripePromise = loadStripe(window._env_.NODE_ENV === 'production' ? 'pk_live_muXXsC8ffasqltXu9HH6zuM9' : 'pk_test_2TEL0VAIvwQv3soY8v5KEj8L', {locale: currentLanguage});
  const reservation = useReservationState();
  const { onFinish, setCurrentStep, filteredRatePlans } = useContext(CheckoutContext);
  setCurrentStep(STEP.payment);
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const propertyFromURL = searchParams.get('property');
  const roomFromURL = parseInt(searchParams.get('room'), 10);
  const startDateFromURL = searchParams.get('checkin');
  const endDateFromURL = searchParams.get('checkout');
  const adultsFromURL = parseInt(searchParams.get('adults'), 10);
  const childrenFromURL = parseInt(searchParams.get('children'), 10);
  const babiesFromURL = parseInt(searchParams.get('babies'), 10);
  const boardFromURL = parseInt(searchParams.get('board'), 10);
  const rateFromURL = parseInt(searchParams.get('rate'), 10);
  const promotionFromURL = parseInt(searchParams.get('promotion'), 10);
  const offerFromURL = parseInt(searchParams.get('offer'), 10);

  const {
    state: {
      campings: {
        [reservation.selectedHotelId || propertyFromURL]: camping,
      },
    },
  } = useCampings();
  const { endDate, startDate } = reservation;
  const [postingReservation, setPostingReservation] = useState(false);
  const daysBetweenCheckInAndCheckOut = calcDaysBetweenCheckInAndCheckOut({ endDate, startDate });
  const property = camping;
  const reservationFee = reservation?.ratePlanPropertyPolicy?.depositPercentage;
  const agencyMode = property.ModalidadAgencia;

  const appearance = {
    theme: 'flat',
    variables: {
      fontFamily: ' Lato, Raleway, Arial, Helvetica, sans-serif',
      fontLineHeight: '1.5',
      borderRadius: '10px',
      colorBackground: '#F6F8FA',
      accessibleColorOnColorPrimary: '#262626',
    },
    rules: {
      '.Block': {
        backgroundColor: 'var(--colorBackground)',
        boxShadow: 'none',
        padding: '12px',
      },
      '.Input': {
        padding: '12px',
      },
      '.Input:disabled, .Input--invalid:disabled': {
        color: 'lightgray',
      },
      '.Tab': {
        padding: '10px 12px 8px 12px',
        border: 'none',
      },
      '.Tab:hover': {
        border: 'none',
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)',
      },
      '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
        border: 'none',
        backgroundColor: '#fff',
        boxShadow: '0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)',
      },
      '.Label': {
        fontWeight: '500',
      },
    },
  };

  const options = {
    mode: 'payment',
    amount: parseInt(reservation.totalPrice * reservationFee, 10),
    currency: 'eur',
    paymentMethodCreation: 'manual',
    // Fully customizable with appearance API.
    appearance,
  };

  useEffect(() => {
    if (reservation?.totalPrice === undefined || reservation?.totalPrice === null) {
      navigate(
        `${ROUTES?.book + ROUTES?.resume}?checkin=${moment(
          startDateFromURL,
        )?.format('YYYY-MM-DD')}&checkout=${moment(endDateFromURL)?.format(
          'YYYY-MM-DD',
        )}${rateFromURL && `&rate=${rateFromURL}`}${
          boardFromURL && `&board=${boardFromURL}`
        }${
          offerFromURL !== null ? `&offer=${offerFromURL}` : ''
        }${`&adults=${adultsFromURL}`}${`&children=${childrenFromURL}`}${`&babies=${babiesFromURL}`}${
          promotionFromURL !== null ? `&promotion=${promotionFromURL}` : ''
        }${propertyFromURL ? `&property=${propertyFromURL}` : ''}${
          roomFromURL ? `&room=${roomFromURL}` : ''
        }`,
      );

      const refreshPage = () => {
        window.parent.location = window.parent.location.href;
      };

      refreshPage();
    }
  }, [
    adultsFromURL,
    babiesFromURL,
    boardFromURL,
    childrenFromURL,
    endDateFromURL,
    offerFromURL,
    promotionFromURL,
    propertyFromURL,
    rateFromURL,
    roomFromURL,
    startDateFromURL,
    reservation
  ]);

  return (
    <div className={paymentWrapper}>
      {postingReservation && (
        <PaymentLoader />
      )}
      <PaymentDetails
        totalPrice={parseFloat(reservation.totalPrice).toFixed(2)}
        kampaohService={reservation.kampaohService}
        reservationFee={reservationFee}
        daysBetweenCheckInAndCheckOut={daysBetweenCheckInAndCheckOut}
        agencyMode={agencyMode}
      />

      <Elements stripe={stripePromise} options={options}>
        <CheckoutPage
          setPostingReservation={setPostingReservation}
          reservationFee={reservationFee}
          onFinish={onFinish}
          filteredRatePlans={filteredRatePlans}
        >
          <CancellationPolicy
            totalPrice={parseFloat(reservation.totalPrice).toFixed(2)}
            reservationFee={reservationFee}
            checkinDate={reservation.startDate}
            deadlineDay={reservation.deadlineDay}
            depositPercentage={reservation.depositPercentage}
            nonRefundable={reservation.nonRefundable}
          />
          <PaymentResume
            totalPrice={parseFloat(reservation.totalPrice).toFixed(2)}
            kampaohService={reservation.kampaohService}
            reservationFee={reservationFee}
            agencyMode={agencyMode}
          />
        </CheckoutPage>
        {
            /*
<CheckoutForm
            setPostingReservation={setPostingReservation}
            reservationFee={reservationFee}
            onFinish={onFinish}
          >
            <CancellationPolicy
              totalPrice={parseFloat(reservation.totalPrice).toFixed(2)}
              reservationFee={reservationFee}
              checkinDate={reservation.startDate}
              deadlineDay={reservation.ratePlanPropertyPolicy.deadlineDay}
            />

            <PaymentResume
              totalPrice={parseFloat(reservation.totalPrice).toFixed(2)}
              kampaohService={reservation.kampaohService}
              reservationFee={reservationFee}
              agencyMode={agencyMode}
            />
          </CheckoutForm>
            */
          }
      </Elements>

    </div>
  );
});

export default Payment;
